import styles from "./styles.module.scss";

const Logo = () => {
  return (
    <div className={styles.logo}>
      <svg
        width="30"
        height="32"
        viewBox="0 0 30 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4_1234)">
          <path
            d="M27.1708 12.7851C28.3272 10.1968 28.2935 7.36484 26.5196 5.40334C23.2636 1.79373 19.581 2.97759 15.438 4.47483L15.4941 6.45953L16.9537 7.74785C16.9537 7.74785 21.8152 3.97575 24.8354 6.32026C26.2726 7.44609 27.328 8.76923 26.6655 12.2628"
            fill="#0F9985"
          />
          <path
            d="M15.4378 4.46301L12.6085 5.71651C12.2604 5.8674 12.0359 6.2272 12.0246 6.61021L11.9573 11.8796C16.2013 7.96817 16.9423 7.73604 16.9423 7.73604L15.4266 4.47462L15.4378 4.46301Z"
            fill="#0F9985"
          />
          <path
            d="M23.7803 25.2851C26.531 25.0181 28.8776 23.5789 29.6411 21.0139C31.0333 16.3017 28.2039 13.5974 24.8806 10.6261L23.2526 11.6591L22.9045 13.609C22.9045 13.609 28.4959 16.0811 28.0355 19.9461C27.811 21.7915 27.2271 23.4048 23.9712 24.5539"
            fill="#0F9985"
          />
          <path
            d="M24.88 10.6261L22.4212 8.71105C22.118 8.47892 21.7138 8.4441 21.377 8.64141L16.9309 11.2181C22.3201 13.0635 22.904 13.609 22.904 13.609L24.88 10.6261Z"
            fill="#0F9985"
          />
          <path
            d="M11.6089 28.5001C13.2032 30.833 15.5834 32.2141 18.1209 31.6106C22.7691 30.508 23.6224 26.6082 24.442 22.1513L22.7578 21.2112L20.9502 21.8728C20.9502 21.8728 21.68 28.117 18.1995 29.6375C16.5378 30.3687 14.8986 30.6473 12.305 28.3027"
            fill="#0F9985"
          />
          <path
            d="M24.4422 22.1513L24.8127 18.9943C24.8576 18.6113 24.678 18.2283 24.3524 18.031L19.9736 15.3383C21.1301 21.0835 20.9504 21.8843 20.9504 21.8843L24.4422 22.1629V22.1513Z"
            fill="#0F9985"
          />
          <path
            d="M2.84066 19.215C1.68422 21.8032 1.71791 24.6352 3.49186 26.5967C6.74785 30.2063 10.4305 29.0224 14.5734 27.5252L14.5173 25.5405L13.0577 24.2522C13.0577 24.2522 8.1962 28.0243 5.17599 25.6798C3.73887 24.5539 2.68348 23.2308 3.3459 19.7373"
            fill="#0F9985"
          />
          <path
            d="M14.5619 27.5368L17.3912 26.2833C17.7393 26.1324 17.9638 25.7726 17.975 25.3896L18.0424 20.1202C13.7984 24.0316 13.0574 24.2638 13.0574 24.2638L14.5731 27.5252L14.5619 27.5368Z"
            fill="#0F9985"
          />
          <path
            d="M6.2318 6.71472C3.48105 6.98167 1.1345 8.42087 0.371022 10.9859C-1.02119 15.6981 1.80815 18.4025 5.1315 21.3737L6.7595 20.3407L7.10755 18.3908C7.10755 18.3908 1.505 15.9187 1.96533 12.0537C2.18988 10.2083 2.77372 8.59497 6.0297 7.44593"
            fill="#0F9985"
          />
          <path
            d="M5.11987 21.3737L7.57871 23.2888C7.88185 23.5209 8.28604 23.5558 8.62287 23.3584L13.069 20.7818C7.67975 18.9364 7.09592 18.3909 7.09592 18.3909L5.11987 21.3737Z"
            fill="#0F9985"
          />
          <path
            d="M18.3907 3.49985C16.7964 1.17856 14.4162 -0.214222 11.8787 0.389316C7.23052 1.49193 6.37723 5.39171 5.55762 9.8486L7.24175 10.7887L9.04938 10.1272C9.04938 10.1272 8.31959 3.88287 11.8001 2.36242C13.4618 1.63121 15.101 1.35265 17.6946 3.69716"
            fill="#0F9985"
          />
          <path
            d="M5.55775 9.84852L5.18724 13.0055C5.14233 13.3885 5.32197 13.7715 5.64757 13.9688L10.0263 16.6615C8.86987 10.9163 9.04951 10.1155 9.04951 10.1155L5.55775 9.83691V9.84852Z"
            fill="#0F9985"
          />
        </g>
        <defs>
          <clipPath id="clip0_4_1234">
            <rect
              width="30"
              height="31.5"
              fill="white"
              transform="translate(0 0.25)"
            />
          </clipPath>
        </defs>
      </svg>
      <span>Search&Chat AI</span>
    </div>
  );
};

export default Logo;
