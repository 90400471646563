import { lazy } from "react";

import { CONTACT, HOME, NOT_FOUND, PRIVACY, TERMS } from "./paths";
import MainLayout from "../layout/MainLayout";
import Loadable from "../components/ui/Loadable/Loadable";

const HomePage = Loadable(lazy(() => import("../pages/HomePage/HomePage")));
const TermsPage = Loadable(lazy(() => import("../pages/TermsPage/TermsPage")));
const PrivacyPage = Loadable(
  lazy(() => import("../pages/PrivacyPage/PrivacyPage"))
);
const ContactPage = Loadable(
  lazy(() => import("../pages/ContactPage/ContactPage"))
);
const NotFoundPage = Loadable(
  lazy(() => import("../pages/NotFoundPage/NotFoundPage"))
);

const MainRoutes = {
  path: HOME,
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <HomePage />,
    },
    {
      path: TERMS,
      element: <TermsPage />,
    },
    {
      path: PRIVACY,
      element: <PrivacyPage />,
    },
    {
      path: CONTACT,
      element: <ContactPage />,
    },
    {
      path: NOT_FOUND,
      element: <NotFoundPage />,
    },
  ],
};

export default MainRoutes;
