import { NavLink } from 'react-router-dom'

import { CONTACT, HOME, PRIVACY, TERMS } from '../../../routes/paths'

import styles from './styles.module.scss';

const NavBar = () => {
  return (
      <ul className={styles.navbar}>
        <li>
          <NavLink
            to={HOME}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "activeLink" : ""
            }
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to={TERMS}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "activeLink" : ""
            }
          >
            Terms
          </NavLink>
        </li>
        <li>
          <NavLink
            to={PRIVACY}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "activeLink" : ""
            }
          >
            Privacy
          </NavLink>
        </li>
        <li>
          <NavLink
            to={CONTACT}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "activeLink" : ""
            }
          >
            Contact
          </NavLink>
        </li>
      </ul>
  )
}

export default NavBar