import styles from "./styles.module.scss";

type Props = {
  children: any;
  style?: React.CSSProperties;
};

const Button = ({ children, style, ...defaultProps }: Props) => {
  return (
    <button {...defaultProps} className={styles.button} style={style}>
      {children}
    </button>
  );
};

export default Button;
