import Logo from "../Logo/Logo";
import NavBar from "../NavBar/NavBar";
import Button from "../../forms/Button/Button";

import styles from "./styles.module.scss";

const Header = () => {
  return (
    <div className={`contentWrapper ${styles.header}`}>
      <div className={styles.logoNavWrapper}>
        <Logo />
        <NavBar />
      </div>
      <Button>Add to browser</Button>
    </div>
  );
};

export default Header;
